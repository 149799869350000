<template>
  <div class="rays-tuner-screen">
    <div class="screen-topbar">
      <div class="screen-title">筛选任务</div>
      <!--      简化任务 筛选搜索-->
      <div class="screen-top-tools">
        <a-input
          placeholder="可输入名称关键词"
          v-model:value="search"
          size="large"
          class="screen-search"
          @press-enter="searchList"
        >
          <template #prefix>
            <SearchOutlined />
          </template>
        </a-input>

        <a-select
          ref="select"
          v-model:value="select"
          style="width: 120px"
          class="screen-select"
          @change="searchList"
        >
          <a-select-option value="-1">全部任务</a-select-option>
          <a-select-option value="6">已完成</a-select-option>
          <a-select-option value="2">进行中</a-select-option>
          <a-select-option value="1">队列中</a-select-option>
          <a-select-option value="4">手动停止</a-select-option>
          <a-select-option value="5">系统中止</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="screen-body">
      <a-divider
        style="height: 1px; background-color: #d8d8d8"
        class="divider"
      />

      <!--      筛选任务 空状态图标-->
      <div class="screen-body-scroll">
        <div class="screen-body-empty" v-show="paginationProps.total == 0">
          <div class="screen-body-empty-logo">
            <inbox-outlined :style="{ fontSize: '60px', color: '#D8D8D8' }" />
          </div>
          <div class="screen-body-empty-words">暂无任务</div>
        </div>

        <!--      筛选任务 列表-->
        <div class="screen-body-list" v-show="paginationProps.total > 0">
          <a-table
            id="screen_task_body_scroll"
            :data-source="screenData"
            :columns="screenColumns"
            :showHeader="false"
            :pagination="paginationProps"
            :scroll="{ x: '1500px', y: 'calc(100vh - 217px)' }"
          >
            <template #pic="{ record }">
              <div class="screen-list-pic">
                <a-image
                  :width="72"
                  :height="47"
                  :src="record.url"
                  :preview="false"
                  :fallback="imgSvg"
                />
              </div>
            </template>
            <template #name="{ record }">
              <div class="screen-list-name">
                <a-tooltip placement="topLeft" :destroyTooltipOnHide="true">
                  <template #title>
                    <span
                      >{{ record.parent_task_number }} |
                      {{ record.shader_id_screen }}</span
                    >
                  </template>
                  <div class="screen-list-id">
                    {{ record.parent_task_number }} |
                    {{ record.shader_id_screen }}
                  </div>
                </a-tooltip>
                <div class="screen-list-remark">
                  {{ record.time_and_error }}
                </div>
              </div>
            </template>
            <template #PAT="{ record }">
              <div class="screen-list-info">
                <a-tooltip placement="topLeft" :destroyTooltipOnHide="true">
                  <template #title>
                    <span>{{ record.pat_name }}</span>
                  </template>
                  <div class="screen-list-PAT">
                    {{ record.pat_name }}
                  </div>
                </a-tooltip>
                <div class="screen-list-device">Device:{{ record.device }}</div>
              </div>
            </template>
            <template #time="{ record }">
              <div class="screen-list-time" v-if="record.status == '6'">
                提交时间：{{
                  record.add_time
                }}&nbsp;&nbsp;&nbsp;&nbsp;总用时：{{ record.total_time }}
              </div>
              <div class="screen-list-rate" v-if="record.status != '6'">
                <!--              任务进度：{{ record.rate }}-->
                <a-progress
                  :percent="65"
                  strokeColor="#604bdc"
                  :show-info="false"
                  v-if="record.status == '2'"
                  status="active"
                />
                <a-progress
                  :percent="record.status * 10"
                  strokeColor="#555555"
                  :show-info="false"
                  v-if="record.status != '2'"
                />
              </div>
            </template>
            <template #status="{ record }">
              <div
                class="screen-list-status"
                style="color: #aaaaaa"
                v-if="record.status == '8'"
              >
                删除中
              </div>
              <div
                class="screen-list-status"
                style="color: #aaaaaa"
                v-if="record.status == '2'"
              >
                进行中...
              </div>
              <div
                class="screen-list-status"
                style="color: #604bdc"
                v-if="record.status == '6'"
              >
                已完成
              </div>
              <div
                class="screen-list-status"
                style="color: #3c3c3c"
                v-if="record.status == '3'"
              >
                <div
                  class="screen-list-status-square"
                  style="
                    float: left;
                    background: rgba(255, 184, 0, 0.2);
                    border: 1px solid #ffb800;
                  "
                ></div>
                <div style="margin-left: 16px">停止中...</div>
              </div>
              <div
                class="screen-list-status"
                style="color: #3c3c3c"
                v-if="record.status == '4'"
              >
                <div
                  class="screen-list-status-square"
                  style="
                    float: left;
                    background: rgba(255, 184, 0, 0.2);
                    border: 1px solid #ffb800;
                  "
                ></div>
                <div style="margin-left: 16px">手动停止</div>
              </div>
              <div
                class="screen-list-status"
                style="color: #3c3c3c"
                v-if="record.status == '5'"
              >
                <div
                  class="screen-list-status-square"
                  style="
                    float: left;
                    background: rgba(255, 34, 34, 0.2);
                    border: 1px solid #ff2222;
                  "
                ></div>
                <div style="margin-left: 16px">
                  系统中止&nbsp;
                  <span class="status-reason">
                    <a-tooltip placement="top" :destroyTooltipOnHide="true">
                      <template #title>{{ record.intro }}</template>
                      <QuestionCircleFilled
                        :style="{ fontSize: '12px', color: '#231f20' }"
                      />
                    </a-tooltip>
                  </span>
                </div>
              </div>
              <div
                class="screen-list-status"
                style="color: #aaaaaa"
                v-if="record.status == '1' || record.status == '0'"
              >
                <div
                  class="screen-list-status-square"
                  style="
                    float: left;
                    background: rgba(170, 170, 170, 0.2);
                    border: 1px solid #aaaaaa;
                  "
                ></div>
                <div style="margin-left: 16px">队列中</div>
              </div>
            </template>
            <template #action="{ record }">
              <div class="screen-list-action">
                <!--                删减检测列表图标-->
                <div class="action-detect-logo">
                  <!--                移出检测列表-->
                  <MinusOutlined
                    :style="{ fontSize: '16px', color: '#FFFFFF' }"
                    v-if="record.cart == 1"
                  />
                  <!--                加入检测列表-->
                  <PlusOutlined
                    :style="{ fontSize: '16px', color: '#604BDC' }"
                    v-if="record.cart == 0"
                  />
                </div>

                <a-tooltip
                  placement="bottom"
                  :destroyTooltipOnHide="true"
                  v-if="record.cart == 1"
                >
                  <template #title>
                    <span> 移出检测列表 </span>
                  </template>
                  <div
                    class="action-detect-moveout-bg"
                    v-if="record.cart == 1"
                    @click="detect(record, 0)"
                  ></div>
                </a-tooltip>

                <a-tooltip
                  placement="bottom"
                  :destroyTooltipOnHide="true"
                  v-if="record.cart == 0"
                >
                  <template #title>
                    <span> 加入检测列表 </span>
                  </template>
                  <div
                    class="action-detect-movein-bg"
                    v-if="record.cart == 0"
                    @click="detect(record, 1)"
                  >
                    <PlusOutlined
                      :style="{ fontSize: '16px', color: '#FFFFFF' }"
                      v-if="record.cart == 0"
                    />
                  </div>
                </a-tooltip>

                <!--                操作图标-->
                <div class="action-operate-logo">
                  <!--                已完成-查看-->
                  <DiffOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '6'"
                  />
                  <!--                进行/队列中-暂停-->
                  <PauseOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="
                      record.status == '2' ||
                        record.status == '1' ||
                        record.status == '0'
                    "
                  />
                  <!--                系统中止-重启-->
                  <ReloadOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '5'"
                  />
                  <!--                手动停止-继续-右箭头-->
                  <CaretRightOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '4'"
                  />
                  <!--                停止中不能操作-右箭头-->
                  <CaretRightOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '3'"
                  />
                </div>
                <div
                  class="action-operate-bg"
                  v-if="record.status == '6'"
                  @click="check(record)"
                ></div>
                <div
                  class="action-operate-bg"
                  v-if="
                    record.status == '2' ||
                      record.status == '1' ||
                      record.status == '0'
                  "
                  @click="operate(record, 0)"
                ></div>
                <div
                  class="action-operate-bg"
                  v-if="record.status == '5' || record.status == '4'"
                  @click="operate(record, 1)"
                ></div>
                <div
                  class="action-operate-bg"
                  v-if="record.status == '3' || record.status == '8'"
                  @click="Doing()"
                ></div>
                <!--              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
                <!--                删除图标-->
                <div class="action-delete-logo">
                  <DeleteOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                  />
                </div>
                <a-popconfirm
                  title="确定要删除该任务吗？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="operate(record, 2)"
                  :destroyTooltipOnHide="true"
                  placement="topRight"
                >
                  <div
                    class="action-delete-bg"
                    v-show="record.status != '8' && record.status != '3'"
                  ></div>
                </a-popconfirm>
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <!--    <div class="screen-footer">-->
    <!--      &lt;!&ndash;      <button @click="test()">123</button>&ndash;&gt;-->
    <!--    </div>-->
  </div>
</template>

<script>
// import storage from '../models/storage';
import { message } from 'ant-design-vue';
import {
  SearchOutlined,
  InboxOutlined,
  DeleteOutlined,
  DiffOutlined,
  PauseOutlined,
  ReloadOutlined,
  CaretRightOutlined,
  QuestionCircleFilled,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import { toShaderScreen, setSimplifyHomeInfo } from '@/models/tunerStorage';
// import router from '@/routes';
import store from '@/vuex/store';
import PerfectScrollbar from 'perfect-scrollbar';
import { nextTick, computed, watch } from 'vue';
import moment from 'moment';
import api from '@/api/task';
import { getBaseUrl } from '@/api/service';
import { imgSvg72_47 as imgSvg } from '@/utils/img';
const engineurl = getBaseUrl('engine');

let screenTaskDOM = null;
let screenTaskPs = null;

export default {
  name: 'ScreenTask',
  data() {
    return {
      search: '',
      select: '-1',
      timer: null,
      imgSvg: imgSvg,
      paginationProps: {
        size: 'small',
        showQuickJumper: true,
        position: 'bottom',
        total: 0,
        pageSize: 9,
        current: 1,
        onChange: (current) => {
          // console.log('paginationProps', current, this.paginationProps);
          this.paginationProps.current = current;
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.getList();
        },
      },
      screenData: [],
      screenColumns: [
        {
          title: 'Pic',
          slots: { customRender: 'pic' },
          width: '6%',
          key: 'pic',
          fixed: 'left',
        },
        {
          title: 'Name',
          slots: { customRender: 'name' },
          width: '20%',
          key: 'name',
          fixed: 'left',
        },
        {
          title: 'PAT',
          dataIndex: 'pat_name',
          key: 'pat_name',
          slots: { customRender: 'PAT' },
          width: '18%',
        },
        {
          title: 'Time',
          slots: { customRender: 'time' },
          key: 'time',
          width: '26%',
        },
        {
          title: 'Status',
          slots: { customRender: 'status' },
          dataIndex: 'status',
          key: 'status',
          width: '10%',
        },
        {
          title: 'Action',
          slots: { customRender: 'action' },
          key: 'action',
          width: '10%',
          fixed: 'right',
        },
      ],
      cartData: [],
    };
  },
  components: {
    SearchOutlined,
    InboxOutlined,
    DeleteOutlined,
    DiffOutlined,
    PauseOutlined,
    ReloadOutlined,
    CaretRightOutlined,
    QuestionCircleFilled,
    MinusOutlined,
    PlusOutlined,
  },
  methods: {
    formateDate(start, end) {
      if (
        !start ||
        !end ||
        start == '0000-00-00 00:00:00' ||
        end == '0000-00-00 00:00:00'
      ) {
        return "00'00'00";
      }
      // 对比两个时间相差多少秒
      const second = moment(start).diff(moment(end), 'seconds');
      // 把数值型的毫秒值转化为时分秒格式
      const res = moment.utc(Math.abs(second) * 1000).format(`HH'mm'ss`);
      return res;
    },

    sortPat(tasks) {
      const _pats = [];
      for (let i in tasks) {
        let _pi = -1;
        for (let pindex = 0; pindex < _pats.length; pindex++) {
          if (tasks[i].pat_name == _pats[pindex].patname) {
            _pi = pindex;
            break;
          }
        }
        if (_pi == -1) {
          _pats.push({
            patname: tasks[i].pat_name,
            shaderids: [parseInt(tasks[i].shader_id)],
          });
        } else if (
          !_pats[_pi].shaderids.includes(parseInt(tasks[i].shader_id))
        ) {
          _pats[_pi].shaderids.push(parseInt(tasks[i].shader_id));
        }
      }
      return _pats;
    },

    searchList() {
      this.paginationProps.current = 1;
      this.getList();
    },

    async detect(record, type) {
      const taskInfo = JSON.parse(JSON.stringify(record));
      const user_name = store.state.user.userinfo;
      // console.log('task info:',taskInfo);
      // console.log('type:',type);

      if (type == 0) {
        //移出购物车
        const params = {
          id_arr_str: taskInfo.cart_id,
        };
        const res = await api.cartDel(params);
        if (!res) return;
        store.commit('setShaderCartUpdate', new Date().getTime());
      } else if (type == 1) {
        //加入购物车
        //获取md5
        const pidNum = parseInt(taskInfo.shader_id);
        const params = {
          user: user_name.email,
          pat: taskInfo.pat_name,
          pid: pidNum,
        };
        const res = await api.getOriginalShaderMD5(params);
        if (!res) return;
        const md5 = res.MD5;
        if (this.cartData) {
          const cartList = JSON.parse(JSON.stringify(this.cartData));
          // console.log('cart List:',cartList);
          let count = 0;
          for (let n in cartList) {
            const cartParams = JSON.parse(cartList[n].params || '{}');
            if (md5 == cartParams.md5) {
              message.info(
                ' 存在同源shader在购物车中:[Shader:' +
                  cartParams.completeShader +
                  '];[PAT:' +
                  cartParams.pat +
                  ']'
              );
              count += 1;
              break;
            }
          }
          if (count == 0) {
            this.addDetect(record, md5);
          }
        } else {
          // console.log('no cart data!');
          this.addDetect(record, md5);
        }
        // const md5 = '7195A6193F9A222BE9528EC131685E7E';
      }
    },

    async addDetect(record, md5) {
      const taskInfo = JSON.parse(JSON.stringify(record));
      const user_name = store.state.user.userinfo;

      const info = {
        device_type: taskInfo.device,
        pat: taskInfo.pat_name,
        md5: md5,
        simp_task_id: taskInfo.parent_task_number,
        shader_id: taskInfo.shader_id,
        var_shader_name: taskInfo.var_shader_name,
        highlight: taskInfo.highlight,
        time: taskInfo.time,
        error: taskInfo.error,
        completeShader: taskInfo.shader_id_screen,
      };
      const _param = {
        task_id_arr: taskInfo.id,
        user_id: user_name.uid,
        params_arr: JSON.stringify(info),
      };
      const res = await api.cardAdd(_param);
      if (!res) return;
      store.commit('setShaderCartUpdate', new Date().getTime());
    },

    check(value) {
      const getproid = JSON.parse(JSON.stringify(value));
      // console.log('getproid:',getproid);
      const proID = getproid.id;
      for (let n in this.screenData) {
        if (this.screenData[n].id == proID) {
          const tempParams = this.screenData[n].params;
          const obj = JSON.parse(tempParams);
          const patName = getproid.pat_name;
          const shaderId = this.screenData[n].shader_id;
          const simTaskId = this.screenData[n].parent_id;
          const simTaskNo = obj.simpl_task_id;
          const screenTaskNo = this.screenData[n].task_number;
          toShaderScreen(patName, shaderId, simTaskId, simTaskNo, screenTaskNo);
        }
      }
    },

    Doing() {
      message.warning('操作中，请稍后');
    },

    async getList(count = 0) {
      const user_name = store.state.user.userinfo;
      const _param = {
        user_id: user_name.uid,
        type_id: 3,
      };
      if (this.search) {
        _param.keyword = this.search;
      }
      if (this.select != '-1') {
        _param.status = this.select;
        if (_param.status == '1') {
          _param.status += ',0';
        } else if (_param.status == '4') {
          _param.status += ',3';
        }
      }
      const url = `/index.php/Task/gets?page=${this.paginationProps.current}&pageSize=${this.paginationProps.pageSize}`;
      const res = await api.taskGets(url, _param);
      if (!res) return;
      if (res.code != 0) {
        message.error(' 获取筛选任务列表失败！');
        return;
      }
      this.paginationProps.total = res.total || 0;
      const chargeData = res.data;
      const _list = [];
      let _hasing = false;

      for (let n in chargeData) {
        // 处理params中的缩略图数据
        // const _getHighLight = {
        //   user: user_name.email,
        //   user_id: user_name.uid,
        //   pat:chargeData[n].pat_name,
        //   pid:chargeData[n].shader_id,
        // }
        // axios.post(config.engineurl + '/api/sopt/simplifier/getHighlightList',_getHighLight)
        //     .then((response)=> {
        //   console.log('highlight pic name list',response);
        // }).catch(function (error) {
        //   console.log(error);
        // });
        chargeData[n].key = n;
        const _params = JSON.parse(chargeData[n].params || '{}');
        if (_params.highlight) {
          chargeData[n].url =
            engineurl +
            '/api/sopt/simplifier/getHighlightByFilename?filename=' +
            _params.highlight +
            '&pat=' +
            chargeData[n].pat_name +
            '&user=' +
            user_name.email +
            '&pid=' +
            chargeData[n].shader_id;
          chargeData[n].highlight = _params.highlight;
        } else {
          chargeData[n].url = '';
          chargeData[n].highlight = '';
        }
        chargeData[n].parent_task_number = _params.simpl_task_id;
        chargeData[n].shader_id_screen =
          chargeData[n].shader_id + _params.var_shader_name;
        chargeData[n].var_shader_name = _params.var_shader_name;
        let time = _params.time.toFixed(3);
        let error = _params.error.toFixed(5);
        chargeData[n].time = _params.time;
        chargeData[n].error = _params.error;
        chargeData[n].time_and_error = 'Time:' + time + ' ' + 'Error:' + error;
        chargeData[n].total_time = this.formateDate(
          chargeData[n].start_time,
          chargeData[n].end_time
        );
        chargeData[n].device = _params.device_type;
        chargeData[n].cart = 0;
        //判断是否在购物车
        for (let m in this.cartData) {
          if (chargeData[n].id == this.cartData[m].task_id) {
            //在购物车内
            chargeData[n].cart = 1;
            chargeData[n].cart_id = this.cartData[m].id;
            break;
          }
        }

        if (chargeData[n].status == '3' || chargeData[n].status == '8') {
          _hasing = true;
        }
        _list.push(chargeData[n]);
      }
      this.screenData = _list;
      // console.log('screenData:',this.screenData);

      if (screenTaskDOM && screenTaskPs) {
        nextTick(() => {
          screenTaskPs.update();
        });
      }
      if (!_hasing) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
      } else if (count < 10) {
        this.timer = setTimeout(() => {
          this.getList(++count);
        }, 1000);
      }
    },

    async operate(item, type = 0) {
      this.getList();
      const opts = ['stopAsyncTask', 'continueAsyncTask', 'deleteAsyncTask'];
      const opttips = ['停止', '继续', '删除'];
      const proID = item.task_number;
      // console.log('pause pro id:',proID);
      const user_name = store.state.user.userinfo;
      const email = user_name.email;
      const params = {
        user: email,
        task_id: proID,
      };
      const url = `/api/sopt/simplifier/${opts[type]}`;
      const res = await api.handleTask(url, params, { noMessage: true });
      if (!res || res.code != 0) {
        message.error(opttips[type] + '筛选任务失败！');
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.getList();
      message.success(opttips[type] + '筛选任务成功！');
    },
  },

  setup() {
    return {};
  },
  computed: {
    simplifyHome() {
      return store.state.shaderTool.simplifyHome || {};
    },
  },

  mounted() {
    const tempData = computed(
      () => store.state.imageDetect.originalGoods || []
    );
    const tempData2 = JSON.parse(JSON.stringify(tempData.value));
    this.cartData = tempData2;
    // console.log('computed tempData2222:',tempData2);
    watch(tempData, () => {
      this.cartData = JSON.parse(JSON.stringify(tempData.value));
      this.getList();
    });

    this.getList();

    screenTaskDOM = document.querySelector(
      '#screen_task_body_scroll .ant-table-body'
    );
    screenTaskPs = new PerfectScrollbar(screenTaskDOM);
    if (this.simplifyHome.menuKey[0] != '4') {
      const _simp = JSON.parse(JSON.stringify(this.simplifyHome));
      _simp.menuKey = ['4'];
      setSimplifyHomeInfo(_simp);
    }
  },

  beforeUnmount() {
    if (screenTaskPs) {
      screenTaskPs.destroy();
      screenTaskPs = null;
    }
    screenTaskDOM = null;
  },
};
</script>

<style scoped>
.rays-tuner-screen {
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: #fff;
  overflow: auto;
}
.screen-topbar {
  /*border-style: solid;*/
  /*border-color: #604bdc;*/
  width: 100%;
  height: 40px;
}
.screen-title {
  width: 120px;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: LEFT;
  color: #222222;
  line-height: 32px;
  float: left;
}
.screen-top-tools {
  width: 454px;
  height: 40px;
  float: right;
  position: relative;
  /* border: 1px solid black; */
}
.screen-search {
  width: 320px;
  height: 32px;
  margin-right: 14px;
}
.screen-select {
  position: absolute;
}
.screen-body {
  width: 100%;
  background: white;
  height: calc(100% - 64px);
  position: relative;
}
.screen-body-scroll {
  width: 100%;
  background: white;
  height: 100%;
  position: relative;
  /*border: 3px dashed rgba(0,0,0,.2);*/
  overflow: auto;
  /*height: auto;*/
}
.screen-body-empty {
  position: absolute;
  top: calc(50% - 42px);
  left: calc(50% - 31px);
}
.screen-body-empty-logo {
  height: 62px;
  width: 62px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.screen-body-empty-words {
  width: 62px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Semibold;
  text-align: center;
  color: #d8d8d8;
  line-height: 32px;
}
.divider {
  margin-bottom: 0;
}
.screen-list-pic {
  width: 72px;
  height: 47px;
  overflow: hidden;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  /*padding-bottom: 4px;*/
}
.screen-list-name {
  width: 290px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  height: 47px;
}
.screen-list-id {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 320px;
  height: 24px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #3c3c3c;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen-list-id.middle {
  padding-top: 12px;
}
.screen-body-list .list_icon {
  width: 16px;
  height: 16px;
  vertical-align: 3px;
}
.screen-list-remark {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 290px;
  height: 20px;
  display: inline-block;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #666666;
  line-height: 20px;
  vertical-align: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen-list-info {
  height: 47px;
}
.screen-list-PAT {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 250px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #8c8c8c;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen-list-device {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 290px;
  height: 20px;
  display: inline-block;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #666666;
  line-height: 20px;
  vertical-align: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen-list-time {
  width: 350px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #3c3c3c;
  line-height: 22px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.screen-list-rate {
  width: 330px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.screen-list-status {
  width: 100px;
  position: relative;
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.screen-list-status-square {
  position: absolute;
  top: 17px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
.status-reason {
  min-height: 30px;
}
.screen-list-action {
  width: 150px;
  height: 47px;
  padding-top: 4px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  position: relative;
}

.action-detect-logo {
  position: absolute;
  top: 5px;
  left: 8px;
  width: 32px;
  height: 32px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  z-index: 2;
  pointer-events: none;
}
.action-detect-movein-bg {
  padding-left: 8px;
  line-height: 34px;
  position: absolute;
  top: 8px;
  width: 32px;
  height: 32px;
}
.action-detect-movein-bg:hover {
  background: #604bdc;
  border-radius: 4px;
  z-index: 3;
}
.action-detect-moveout-bg {
  position: absolute;
  background: #aaaaaa;
  border-radius: 4px;
  top: 12px;
  left: 4px;
  width: 24px;
  height: 24px;
}

.action-operate-logo {
  position: absolute;
  left: 60px;
  top: 5px;
  width: 16px;
  height: 16px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  z-index: 2;
  pointer-events: none;
}
.action-operate-bg {
  position: absolute;
  top: 8px;
  left: 52px;
  width: 32px;
  height: 32px;
}
.action-operate-bg:hover {
  background: #604bdc;
  border-radius: 4px;
}
.action-delete-logo {
  position: absolute;
  left: 112px;
  top: 5px;
  width: 16px;
  height: 16px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  z-index: 2;
  pointer-events: none;
}
.action-delete-bg {
  position: absolute;
  top: 8px;
  left: 104px;
  width: 32px;
  height: 32px;
}
.action-delete-bg:hover {
  background: #ff2222;
  border-radius: 4px;
}
#screen_task_body_scroll {
  height: 100%;
}
:deep(.ant-table) {
  line-height: 2.85;
  min-height: calc(100vh - 217px);
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.screen-footer {
  width: 100%;
  height: 30px;
  padding-top: 3px;
}
.screen-footer-tool {
  padding-bottom: 20px;
  padding-right: 30px;
  float: right;
}
</style>
<style>
#screen_task_body_scroll .ps__rail-x,
#screen_task_body_scroll .ps__rail-y {
  z-index: 2;
}
#screen_task_body_scroll img {
  background-color: #222;
  border-radius: 4px;
}
</style>
